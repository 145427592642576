import { createTheme } from '@material-ui/core/styles';
import typography from './Typography';
import shadows from './Shadows';

const SidebarWidth = 265;
const TopbarHeight = 70;

const baseTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: '#1a97f5',
      light: '#e6f4ff',
    },
    secondary: {
      main: '#1e4db7',
    },
    background: {
      default: '#fff',
    },
    success: {
      main: '#39cb7f',
      contrastText: '#ffffff',
    },
    danger: {
      main: '#fc4b6c',
    },
    error: {
      main: '#fc4b6c',
    },
    warning: {
      main: '#fdd43f',
      contrastText: '#ffffff',
    },
    text: {
      secondary: '#777e89',
      danger: '#fc4b6c',
    },
  },
  shape: {
    borderRadius: 5,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
          margin: 0,
          padding: 0,
        },
        '#root': {
          height: '100%',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '4000px',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      color: '#949db2',
      '@media(min-width:1280px)': {
        minHeight: TopbarHeight,
        padding: '0 0px',
      },
      '@media(max-width:1280px)': {
        minHeight: '64px',
      },
    },
  },
  status: {
    danger: '#e53e3e',
  },
  typography,
  shadows,
});

export { TopbarHeight, SidebarWidth, baseTheme };
