import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import './App.css';
import { setAuthToken } from './api/authHelper';
import { baseTheme } from './assets/global/Theme-variable';
import { setCompanySubscription, setEmail, setIsLogin, setIsSubscriptionActive, setIsSubscriptionCancelled } from './redux/slices/userBehaviorSlice';
import ThemeRoutes from './routes/Router';

function App() {
  const dispatch = useDispatch();
  const setTokenResult = setAuthToken();
  if (setTokenResult.isSuccessLogin) {
    if (setTokenResult.role === 'Company') {
      dispatch(setCompanySubscription(true));
    }
    dispatch(setIsLogin(true));
    dispatch(setEmail(setTokenResult.email));
  }

  dispatch(setIsSubscriptionActive(setTokenResult.isSubscriptionActive));
  dispatch(setIsSubscriptionCancelled(setTokenResult.isSubscriptionCancelled));
  const routing = useRoutes(ThemeRoutes);
  const theme = baseTheme;
  return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
}

export default App;
