import { createSlice } from '@reduxjs/toolkit';
const initState = {
  isSpinnerVisible: false,
};
const filterSlice = createSlice({
  name: 'spinnerStore',
  initialState: initState,
  reducers: {
    setSpinnerVisibility(state, action) {
      state.isSpinnerVisible = action.payload;
    },
  },
});

export const { setSpinnerVisibility } = filterSlice.actions;
export default filterSlice.reducer;
