import { createSlice } from '@reduxjs/toolkit';
const initState = {
  isErrorPopupVisible: false,
  errorMessage:
    'Something went wrong, please contact the administrator to resolve the issue. Email: admin@fabreq.com',
};
const filterSlice = createSlice({
  name: 'utilityStore',
  initialState: initState,

  reducers: {
    reset: () => initState,
    setErrorPopupVisibility(state, action) {
      state.isErrorPopupVisible = action.payload;
    },
    setErrorErrorMessage(state, action) {
      state.isErrorPopupVisible = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { setErrorPopupVisibility, setErrorErrorMessage, reset } = filterSlice.actions;
export default filterSlice.reducer;
