import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showAttributeForm: false,
  title: '',
  searchValueId: null,
  searchValueFilterId: null,
  selectedDataSetSearchValueId: null,
  selectedColumnId: 0,
  selectedDataSetId: null,
  isSyncMode: false,
  initialAttributes: [{}],
  rawDataRows: [],
  rawDataColumns: [],
  dataTable: {
    columnNames: [],
    rows: [],
    attributes: [],
  },
};
const filterSlice = createSlice({
  name: 'dataTableStore',
  initialState,
  reducers: {
    reset: () => initialState,
    setAttributeFormVisibility(state, action) {
      state.showAttributeForm = action.payload;
    },
    setSearchValueId(state, action) {
      state.searchValueId = action.payload;
    },
    setSearchValueFilterId(state, action) {
      state.searchValueFilterId = action.payload;
    },

    setDataTableAttributes(state, action) {
      state.dataTable['attributes'] = action.payload;
    },
    setDataTableData(state, action) {
      state.dataTable['rows'] = [...action.payload.rows];
      state.dataTable['columnNames'] = [
        ...state.dataTable['columnNames'],
        ...action.payload.columnNames,
      ];
    },
    resetDataTableData(state, action) {
      state.dataTable['rows'] = action.payload.rows;
      state.dataTable['columnNames'] = action.payload.columnNames;
    },

    setSelectedColumnId(state, action) {
      state.selectedColumnId = action.payload;
    },
    setInitialAttributes(state, action) {
      state.initialAttributes = action.payload;
    },

    setDataTableRawData(state, action) {
      state.rawDataColumns.push(...action.payload.rawDataColumns);
      state.rawDataRows.push(...action.payload.rawDataRows);
    },

    resetDataTableRawData(state, action) {
      state.rawDataColumns = action.payload.rawDataColumns;
      state.rawDataRows = action.payload.rawDataRows;
    },

    setSelectedDataSetId(state, action) {
      state.selectedDataSetId = action.payload;
    },
    setSelectedDataSetSearchValueId(state, action) {
      state.selectedDataSetSearchValueId = action.payload;
    },
    SetSyncMode(state, action) {
      state.isSyncMode = action.payload;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    resetAttributes(state, action) {
      state.dataTable['attributes'] = action.payload;
    },

    resetDataTableToDefault(state, action) {
      state.rawDataRows = [];
      state.rawDataColumns = [];
      state.dataTable['columnNames'] = [];
      state.dataTable['rows'] = [];
      state.dataTable['attributes'] = [];
      state.selectedDataSetId = null;
      state.selectedDataSetSearchValueId = null;
    },
  },
});

export const {
  setAttributeFormVisibility,
  setSearchValueId,
  setDataTableAttributes,
  setSelectedColumnId,
  setInitialAttributes,
  setDataTableRawData,
  setSelectedDataSetId,
  setSelectedDataSetSearchValueId,
  SetSyncMode,
  setDataTableData,
  resetDataTableData,
  resetDataTableRawData,
  resetAttributes,
  resetDataTableToDefault,
  reset,
  setSearchValueFilterId,
  setTitle,
} = filterSlice.actions;
export default filterSlice.reducer;
