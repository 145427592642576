import { configureStore } from '@reduxjs/toolkit';
import userBehaviorSlice from './slices/userBehaviorSlice';
import dataTableStore from './slices/dataTableSlice';
import dataSetSlice from './slices/dataSetSlice';
import spinnerSlice from './slices/spinnerSlice';
import utilitySlice from './slices/utilitySlice';
import adsSlice from './slices/adsSlice';
import footerSlice from './slices/footerSlice';
import homePageSlice from './slices/homeSlice'

export const store = configureStore({
  reducer: {
    userStore: userBehaviorSlice,
    dataTableStore: dataTableStore,
    dataSetStore: dataSetSlice,
    spinnerStore: spinnerSlice,
    utilityStore: utilitySlice,
    adsStore: adsSlice,
    footerStore: footerSlice,
    homePageStore:homePageSlice
  },
});
