import { createSlice } from '@reduxjs/toolkit';
const initState = {
  showUserForm: false,
  showSingUpForm: false,
  showEditPersonalInfoPopup: false,
  email: '',
  password: '',
  isLogin: false,
  isSubscriptionActive: false,
  isCompanySubscription: false,
  isSubscriptionCancelled: false,
  userData: {
    userName: '',
    billingAddress: '',
    phoneNumber: '',
    companyName: '',
    accountBalance: 0,
  },
};
const filterSlice = createSlice({
  name: 'userStore',
  initialState: initState,
  reducers: {
    resetUserData: () => initState,
    setDisplayLoginForm(state, action) {
      state.showUserForm = action.payload;
    },

    setDisplaySignUpForm(state, action) {
      state.showSingUpForm = action.payload;
    },
    setIsSubscriptionCancelled(state, action) {
      state.isSubscriptionCancelled = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setIsLogin(state, action) {
      state.isLogin = action.payload;
    },
    setIsSubscriptionActive(state, action) {
      state.isSubscriptionActive = action.payload;
    },
    setCompanySubscription(state, action) {
      state.isCompanySubscription = action.payload;
    },

    setVisibilityEditPersonalInfoPopup(state, action) {
      state.showEditPersonalInfoPopup = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
  },
});

export const {
  setDisplayLoginForm,
  setEmail,
  setPassword,
  setIsLogin,
  setCompanySubscription,
  setIsSubscriptionActive,
  setVisibilityEditPersonalInfoPopup,
  setUserData,
  resetUserData,
  setDisplaySignUpForm,
  setIsSubscriptionCancelled
} = filterSlice.actions;
export default filterSlice.reducer;
