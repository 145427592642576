import { createSlice } from '@reduxjs/toolkit';
const initState = {
  isSidebarOpen: false,
  isDataTableVisible: false,
  urlId: '',
  items: [],
};
const filterSlice = createSlice({
  name: 'dataSetStore',
  initialState: initState,
  reducers: {
    clearDataSetItems(state, action) {
      state.isDataTableVisible = action.payload === true ? true : false;
      state.items = [];
    },
    setDataSetItems(state, action) {
      state.items = [...state.items, ...action.payload];
    },
    setDataTableVisibility(state, action) {
      state.isDataTableVisible = action.payload;
    },
    setUrlId(state, action) {
      state.urlId = action.payload;
    },
    clearUrlId(state, action) {
      state.urlId = undefined;
    },
    setIsSidebarOpen(state, action) {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const {
  setDataSetItems,
  setDataTableVisibility,
  clearDataSetItems,
  setUrlId,
  clearUrlId,
  setIsSidebarOpen,
} = filterSlice.actions;
export default filterSlice.reducer;
