import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import Spinner from './pages/Spinner/Spinner';
ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
  </Suspense>,

  document.getElementById('root'),
);
