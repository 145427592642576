import { createSlice } from '@reduxjs/toolkit';

const initState = {
  isAddAdsVisible: false,
  selectedItem: {},
  rows: [],
};
const adsSlice = createSlice({
  name: 'adsStore',
  initialState: initState,
  reducers: {
    resetAds: () => initState,
    setIsAddAdsVisible(state, action) {
      state.isAddAdsVisible = action.payload;
    },
    setCampaigns(state, action) {
      if (Array.isArray(action.payload)) {
        state.rows = action.payload;
      } else {
        state.rows = [...state.rows, action.payload];
      }
    },
    setSelectedItem(state, action) {
      state.selectedItem = action.payload;
    },
  },
});

export const { setIsAddAdsVisible, setCampaigns, setSelectedItem, resetAds } = adsSlice.actions;
export default adsSlice.reducer;
