import { createSlice } from '@reduxjs/toolkit';

const initState = {
  isHomePage: false,
};
const homePageSlice = createSlice({
  name: 'homePageStore',
  initialState: initState,
  reducers: {
    setIsHomePage(state, action) {
      state.isHomePage = action.payload;
    },
  },
});

export const { setIsHomePage } = homePageSlice.actions;
export default homePageSlice.reducer;
