const typography = {
  fontFamily: 'Helvetica',
  body1: {
    fontWeight: 400, // or 'bold'
  },
  h1: {
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '42px',
    lineHeight: '120%',
    textAlign: 'Center',
    fontFamily: 'Helvetica',
    margin: 0,
  },
  h2: {
    fontWeight: 600,
    fontSize: 24,
    fontFamily: 'Helvetica',
    margin: 0,
  },
  h3: {
    fontWeight: 500,
    fontFamily: 'Helvetica',
    fontSize: 21,
    margin: 0,
  },
  h4: {
    fontWeight: 400,
    fontFamily: 'Helvetica',
    fontSize: 18,
    lineHeight: '21.6px',
    margin: 0,
  },
  h5: {
    fontWeight: 400,
    fontFamily: 'Helvetica',
    fontSize: 18,
    lineHeight: '21.6px',
    margin: 0,
  },
  h6: {
    fontWeight: 400,
    fontFamily: 'Helvetica',
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  buttons: {
    textTransform: 'none',
  },
};

export default typography;
