import { createSlice } from '@reduxjs/toolkit';
const initState = {
  ads: [],
};
const filterSlice = createSlice({
  name: 'footerStore',
  initialState: initState,
  reducers: {
    setFooterAds(state, action) {
      state.ads = action.payload;
    },
  },
});

export const { setFooterAds } = filterSlice.actions;
export default filterSlice.reducer;
