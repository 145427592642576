import React, { lazy } from 'react';
/****Layouts*****/
const FullLayout = lazy(() => import('../layouts/FullLayout/FullLayout'));
/****End Layouts*****/

/*****Pages******/
const SuccessPayment = lazy(() => import('../components/Stripe/SuccessPayment/SuccessPayment'));
const CanceledPayment = lazy(() => import('../components/Stripe/CanceledPayment/CanceledPayment'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard.js'));
const Main = lazy(() => import('../pages/Main/Main.js'));

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Main /> },
      { path: '/data', element: <Dashboard /> },
    ],
  },

  {
    path: '/payment-success',
    element: <SuccessPayment />,
  },
  {
    path: '/payment-canceled',
    element: <CanceledPayment />,
  },
];

export default ThemeRoutes;
