import axios from 'axios';
import { setSpinnerVisibility } from '../redux/slices/spinnerSlice';
import { reset, setErrorErrorMessage, setErrorPopupVisibility } from '../redux/slices/utilitySlice';
import { GetBaseURL } from './apiEndpoint';

export const AddDataSet = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`DataSet/saveDataSet`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorPopupVisibility(true));
    dispatch(setSpinnerVisibility(false));
  }
};

export const UpdateDataSet = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`DataSet/updateDataSet`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorPopupVisibility(true));
    dispatch(setSpinnerVisibility(false));
  }
};

export const RegisterApiRequest = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`user/register`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(reset);
    dispatch(setSpinnerVisibility(false));
  }
};

export const ResetPasswordVerificationApiRequest = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`user/forgotPasswordVerifyCode`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(reset);
    dispatch(setSpinnerVisibility(false));
  }
};

export const ResetPasswordApiRequest = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`user/resetPassword`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(setSpinnerVisibility(false));
  }
};

export const RefreshTokenApiRequest = async (object) => {
  try {
    let url = GetBaseURL(`user/refreshToken`);
    const response = await axios.post(url, object);
    return response.data;
  } catch (error) {}
};

export const AddCampaigns = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`Advertising/addCampaigns`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(setSpinnerVisibility(false));
  }
};

export const UpdateUserInfo = async (dispatch, newRecord) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`user/updateUserInfo`);
    const response = await axios.post(url, newRecord);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(setSpinnerVisibility(false));
  }
};

export const SetAdsOnClick = async (newRecord) => {
  try {
    let url = GetBaseURL(`advertising/setClick`);
    const response = await axios.post(url, newRecord);
    return response;
  } catch (error) {}
};

export const CancelCampaignClick = async (newRecord) => {
  try {
    let url = GetBaseURL(`advertising/cancelCampaign`);
    const response = await axios.post(url, newRecord);
    return response;
  } catch (error) {}
};

export const deleteBannerImage = async (dispatch, record) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`Advertising/deleteBannerImage`);
    const response = await axios.post(url, record);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(setSpinnerVisibility(false));
  }
};

//
export const CheckoutSubscription = async (dispatch, record) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`Subscription/create-checkout-session`);
    const response = await axios.post(url, record);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(setSpinnerVisibility(false));
  }
};

export const CancelSubscription = async (dispatch, record) => {
  try {
    dispatch(setSpinnerVisibility(true));
    let url = GetBaseURL(`Subscription/cancel-subscription`);
    const response = await axios.post(url, record);
    dispatch(setSpinnerVisibility(false));
    return response;
  } catch (error) {
    dispatch(setErrorErrorMessage(error.response.data[0]));
    dispatch(setSpinnerVisibility(false));
  }
};
